import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import css from "styled-jsx/css";
import "ress/ress.css";
import { Color, Variables } from "./designToken";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import Favicon from "../images/meta/favicon.png";
import AppleTouchIcon from "../images/meta/apple-touch-icon.png";

const resetStyle = css.global`
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
`;

const baseStyle = css.global`
  @font-face {
    font-family: "Metric";
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/metric-web-regular.woff2"), url("/fonts/metric-web-regular.woff"),
      url("/fonts/metric-web-regular.eot");
  }
  @font-face {
    font-family: "Metric";
    font-style: italic;
    font-weight: normal;
    src: url("/fonts/metric-web-regular-italic.woff2"), url("/fonts/metric-web-regular-italic.woff"),
      url("/fonts/metric-web-regular-italic.eot");
  }
  @font-face {
    font-family: "Metric";
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/metric-web-medium.woff2"), url("/fonts/metric-web-medium.woff"),
      url("/fonts/metric-web-medium.eot");
  }
  @font-face {
    font-family: "Metric";
    font-style: italic;
    font-weight: 500;
    src: url("/fonts/metric-web-medium-italic.woff2"), url("/fonts/metric-web-medium-italic.woff"),
      url("/fonts/metric-web-medium-italic.eot");
  }
  @font-face {
    font-family: "Metric";
    font-style: normal;
    font-weight: bold;
    src: url("/fonts/metric-web-medium.woff2"), url("/fonts/metric-web-medium.woff"),
      url("/fonts/metric-web-medium.eot");
  }
  @font-face {
    font-family: "Metric";
    font-style: italic;
    font-weight: bold;
    src: url("/fonts/metric-web-medium-italic.woff2"), url("/fonts/metric-web-medium-italic.woff"),
      url("/fonts/metric-web-medium-italic.eot");
  }
  @font-face {
    font-family: "Buttervill";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/Buttervill.otf"), url("/fonts/Buttervill.ttf");
  }
  body {
    background: ${Color.white};
    color: ${Color.defaultText};
    font-size: 15px;
    font-weight: normal;
    line-height: 1.8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "palt";
    font-family: Metric, "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", YuGothic,
      "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  }
  body main {
    width: 100%;
    position: relative;
    overflow-x: hidden;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  dt,
  th {
    font-weight: 500;
  }
  /* To adjust breakline position */
  p span,
  h1 span,
  h2 span,
  h3 span {
    display: inline-block;
  }
  /* TODO: Make component */
  .section {
    width: 100%;
    padding: 0 20px;
  }
  /* TODO: Make component */
  .section .sectionInner {
    margin: 0 auto;
    max-width: ${Variables.siteWidth};
    padding: 80px 0;
  }
  @media (${Variables.breakPoint.sp}) {
    .section .sectionInner {
      padding: 40px 0;
    }
  }
`;

type Props = {
  title?: string;
  children: React.ReactNode;
  ogpDescription?: string;
  ogpImageUrl?: string;
  ogpTitle?: string;
  ogpUrl?: string;
  hasStickyHeader?: boolean;
};

export const Layout: React.FC<Props> = ({
  title,
  children,
  ogpDescription,
  ogpImageUrl,
  ogpTitle,
  ogpUrl,
  hasStickyHeader = false,
}) => {
  const [isHeaderFixed, setIsHeaderFixed] = useState<boolean>(!hasStickyHeader);
  const scrollHandler = () => {
    if (!hasStickyHeader) return null;

    const threshold = window.innerWidth > 767 ? 500 : 300;
    setIsHeaderFixed(window.pageYOffset > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const { site } = useStaticQuery<GatsbyTypes.SiteMetaQuery>(graphql`
    query SiteMeta {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);
  const metaTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title;
  const metaOgpTitle = ogpTitle || metaTitle;
  const metaOgpDescription = ogpDescription || site.siteMetadata.description;
  const metaOgpImage = ogpImageUrl || "https://about.refcome.com/ogp.png";

  return (
    <>
      <style jsx global>
        {resetStyle}
      </style>
      <style jsx global>
        {baseStyle}
      </style>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        title={metaTitle}
        meta={[
          {
            name: `description`,
            content: metaOgpDescription,
          },
          {
            property: `og:title`,
            content: metaOgpTitle,
          },
          {
            property: `og:description`,
            content: metaOgpDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: ogpUrl || "https://about.refcome.com/",
          },
          {
            property: `og:image`,
            content: metaOgpImage,
          },
          {
            property: `fb:app_id`,
            content: `3048160935200037`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: metaOgpTitle,
          },
          {
            name: `twitter:description`,
            content: metaOgpDescription,
          },
          {
            name: `twitter:image`,
            content: metaOgpImage,
          },
        ]}
      >
        <link rel="icon" type="image/png" href={Favicon} />
        <link rel="apple-touch-icon" href={AppleTouchIcon} />
        <script src="/lib/HTMLFormElement-HTMLInputElement.reportValidity.js" defer />
      </Helmet>
      <Header fixed={isHeaderFixed} />
      <main id="main">{children}</main>
      <Footer />
    </>
  );
};
