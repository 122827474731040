import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Color, Variables } from "./designToken";
import { NAV_ITEMS } from "../constants/navigation";

type Props = {
  fixed: boolean;
  isIndexPage?: boolean;
};

export const Header: React.FC<Props> = ({ fixed, isIndexPage }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 70, placeholder: NONE)
        }
      }
    }
  `);
  return (
    <>
      <style jsx>{`
        .wrapper {
          opacity: ${fixed ? 1 : 0};
          pointer-events: ${fixed ? "auto" : "none"};
          position: fixed;
          top: 0;
          background-color: ${Color.white};
          transition: opacity 0.3s ease;
          border-bottom: solid 1px ${Color.subBg};
          width: 100%;
          padding: 0 20px;
          z-index: 100;
        }
        .nav {
          display: flex;
          align-items: center;
          position: relative;
          padding: 15px 0px;
          margin: 0 auto;
          max-width: ${Variables.siteWidth};
        }
        .logo {
          display: flex;
        }
        .logo :global(a) {
          width: 20px;
          display: block;
        }
        .navListItem {
          margin: 0;
          transition: all 0.3s ease;
        }
        .navListItem :global(a) {
          display: block;
        }
        .navListItem + .navListItem {
          margin-left: 30px;
        }
        .navListItem:hover {
          opacity: 0.7;
        }
        .navListItem :global(span) {
          color: ${Color.lightText};
          font-size: 18px;
          font-weight: bold;
        }
        @media (${Variables.breakPoint.maxSp}) {
          .navList {
            display: none;
          }
        }
        @media (${Variables.breakPoint.minPc}) {
          .navList {
            margin: 0 0 0 auto;
            display: flex;
            align-items: flex-end;
          }
        }
      `}</style>
      <header className="wrapper">
        <nav className="nav">
          <div className="logo">
            <Link to="/">
              <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt="" />
            </Link>
          </div>
          <ul className="navList">
            <li className="navListItem">
              {isIndexPage ? (
                <AnchorLink href="#service" offset="70">
                  <span>{NAV_ITEMS.SERVICE.NAME}</span>
                </AnchorLink>
              ) : (
                <Link to="/#service">
                  <span>{NAV_ITEMS.SERVICE.NAME}</span>
                </Link>
              )}
            </li>
            <li className="navListItem">
              <Link to="/member">
                <span>{NAV_ITEMS.MEMBER.NAME}</span>
              </Link>
            </li>
            <li className="navListItem">
              <Link to="/news">
                <span>{NAV_ITEMS.NEWS.NAME}</span>
              </Link>
            </li>
            <li className="navListItem">
              {isIndexPage ? (
                <AnchorLink href="#company" offset="70">
                  <span>{NAV_ITEMS.COMPANY.NAME}</span>
                </AnchorLink>
              ) : (
                <Link to="/#company">
                  <span>{NAV_ITEMS.COMPANY.NAME}</span>
                </Link>
              )}
            </li>
            <li className="navListItem">
              <Link to="/contact">
                <span>{NAV_ITEMS.CONTACT.NAME}</span>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
