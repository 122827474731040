import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Color, Variables } from "./designToken";
import wantedlySvg from "../images/wantedly.svg";
import twitterSvg from "../images/twitter.svg";
import noteSvg from "../images/note.svg";
import { ExternalLink } from "~/atoms/ExternalLink";

export const Footer: React.FC<{}> = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "refcomeLogo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 100, placeholder: NONE)
        }
      }
    }
  `);
  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${Color.subBg};
          width: 100%;
          padding: 40px 20px;
        }
        .container {
          display: flex;
          position: relative;
          padding: 0;
          margin: 0 auto;
          max-width: 1000px;
        }
        .logo {
          position: relative;
          width: 180px;
          height: 64px;
        }
        .links {
          display: flex;
        }
        .linkTitle {
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 3px;
        }
        .linksListItem {
          line-height: 1.7;
          margin-top: 5px;
          font-size: 12px;
        }
        .linksListItem :global(a) {
          color: ${Color.defaultText};
        }
        .linksListItem :global(a:hover),
        .linksListItem :global(a:visited),
        .linksListItem :global(a:active) {
          color: ${Color.defaultText};
        }
        .linksListItem :global(a:hover) {
          text-decoration: underline;
        }
        .credit {
          padding: 0 30px 0 20px;
          max-width: 1000px;
          display: flex;
          margin: 50px auto 0;
        }
        .creditText {
          color: ${Color.lightText};
        }
        .sns {
          margin-left: auto;
        }
        .sns img {
          width: 25px;
          height: 25px;
          margin-left: 10px;
        }
        @media (${Variables.breakPoint.maxSp}) {
          .wrapper {
            margin: 50px auto 0;
          }
          .container {
            flex-direction: column;
            align-items: center;
          }
          .links {
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;
          }
          .linksNav {
            width: calc(50% - 10px);
            margin: 20px 0 0 10px;
          }
          .credit {
            padding: 0;
          }
          .creditText {
            font-size: 10px;
          }
        }
        @media (${Variables.breakPoint.minPc}) {
          .wrapper {
            margin: 100px auto 0;
          }
          .links {
            width: calc(100% - 180px);
            padding: 0 0 0 50px;
          }
          .linksNav {
            width: calc(100% / 4);
          }
          .linksNav + .linksNav {
            margin-left: 30px;
          }
          .credit {
            padding: 0 30px 0 20px;
          }
          .creditText {
            font-size: 12px;
          }
        }
      `}</style>
      <footer className="wrapper">
        <div className="container">
          <nav className="logo">
            <Link to="/">
              <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt="" />
            </Link>
          </nav>
          <div className="links">
            <nav className="linksNav">
              <h5 className="linkTitle">サービス</h5>
              <ul className="linksList">
                <li className="linksListItem">
                  <ExternalLink to="https://jp.refcome.com/">Refcome</ExternalLink>
                </li>
                <li className="linksListItem">
                  <ExternalLink to="https://refcome.team/">Refcome Teams</ExternalLink>
                </li>
              </ul>
            </nav>
            <nav className="linksNav">
              <h5 className="linkTitle">メディア</h5>
              <ul className="linksList">
                <li className="linksListItem">
                  <ExternalLink to="https://jp.refcome.com/insight">Refcome Insight</ExternalLink>
                </li>
              </ul>
            </nav>
            <nav className="linksNav">
              <h5 className="linkTitle">お問い合わせ</h5>
              <ul className="linksList">
                <li className="linksListItem">
                  <ExternalLink to="https://jp.refcome.com/inquiry/contact">Refcomeについて</ExternalLink>
                </li>
              </ul>
            </nav>
            <nav className="linksNav">
              <h5 className="linkTitle">規約</h5>
              <ul className="linksList">
                <li className="linksListItem">
                  <ExternalLink to="https://jp.refcome.com/privacy">個人情報の取り扱いについて</ExternalLink>
                </li>
                <li className="linksListItem">
                  <ExternalLink to="https://jp.refcome.com/privacy/statement">プライバシーポリシー</ExternalLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="credit">
          <p className="creditText">Copyright © Refcome, Inc. All rights reserved.</p>
          <div className="sns">
            <ExternalLink to="https://www.wantedly.com/companies/refcome" target="_blank">
              <img src={wantedlySvg} alt="wantedly" />
            </ExternalLink>
            <ExternalLink to="https://twitter.com/refcome" target="_blank">
              <img src={twitterSvg} alt="twitter" />
            </ExternalLink>
            <ExternalLink to="https://note.mu/refcome/magazines" target="_blank">
              <img src={noteSvg} alt="note" />
            </ExternalLink>
          </div>
        </div>
      </footer>
    </>
  );
};
