const Pallet = {
  white: "#ffffff",
  black: "#3E3E3E",
  gray1: "#ACACAC",
  gray2: "#F5F5F5",
  orange: "#FF6400",
  red: "#EC6B2C",
  darkBlue: "#556D9C",
  blue: "#8493B0",
  mintBlue: "#4EA1B4",
};

const Semantics = {
  defaultText: Pallet.black,
  lightText: Pallet.gray1,
  brand: Pallet.orange,
  accent: Pallet.darkBlue,
  accent2: Pallet.blue,
  subBg: Pallet.gray2,
  accsentBlue: Pallet.mintBlue,
};

export const Color = {
  ...Pallet,
  ...Semantics,
};

const siteWidth = 1200;

export const Variables = {
  siteWidth: `${siteWidth}px`,
  breakPoint: {
    smallSp: "max-width: 600px",
    sp: "max-width: 767px",
    tablet: `max-width: ${siteWidth - 1}px`,
    pc: `min-width: ${siteWidth}px`,
    maxSp: "max-width: 767px",
    minPc: "min-width: 768px",
  },
};
